import React from 'react'
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components'
import media from '../common/mediaQuery'
import { Label, H2 } from '../common/type'

const TextBlock = props => {  
  return (
    <TextBlockWrapper id={props.label}>
      <Label as="h2">{props.label}</Label>
      <H2 as="p">{ReactHtmlParser(props.copy)}</H2>
    </TextBlockWrapper>
  );
};

export default TextBlock;

const TextBlockWrapper = styled.div`
  padding: 120px 20px 0;
  position: relative;

  ${media.tablet`
    flex: 0 0 89%;
    padding: 120px 40px 0;
  `}

  h2 {
    text-transform: capitalize;
    
    +p {
      margin: 23px 0 0;

      ${media.tablet`
        margin: 46px 0 0;
      `}
    }
  }

  +div {
    padding: 90px 20px 0;

    ${media.tablet`
      padding: 200px 40px 0;
    `}
  }
`;
