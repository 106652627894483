import React, { Component } from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import media from '../components/common/mediaQuery'
import Hero from '../components/blocks/hero'
import TextBlock from '../components/blocks/text-block'

class IndexPage extends Component {
  state = {
    textBlocks: [
      { id: 1, label: 'about', copy: 'We are a collective with roots in New York City & Stockholm.<br><br>Specializing in e-commerce and all things web, we partner with brands to curate, create and launch innovative digital experiences.<br><br>Passionate about thoughtful and user-centric design, we assemble small, well-versed teams dedicated to growing your brand and prepping you for the journey ahead.' },
      { id: 2, label: 'services', copy: 'Art Direction / Branding / Development / Product Design / Product Roadmapping / Project Planning / Strategy / UX Design / Web Design' },
      { id: 3, label: 'platforms', copy: 'Contentful / Prismic / Sanity / Shopify / Wordpress' },
      { id: 4, label: 'contact', copy: '<a href="mailto:hi@ofafriend.co">hi@ofafriend.co</a>' }
    ]
  }
  
  render() { 
    return (
      <Layout>
        <SEO title="Friend of a Friend" keywords={[`friend`, `e-commerce`, `shopify`, `agency`, 'new york']} />

        <Row>
          <Hero />
          { this.state.textBlocks.map(block => <TextBlock key={block.id} label={block.label} copy={block.copy} />) }
        </Row>
      </Layout>
    );
  }
}
 
export default IndexPage;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0 120px;
  scroll-behavior: smooth;

  ${media.tablet`
    height: 100vh;
    overflow: scroll;
    padding: 0 0 500px;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;
