import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { theme } from '../global'
import media from '../common/mediaQuery'
import { H1 } from '../common/type'
import AnimatedText from '../common/animatedText'

const textArray = ['active', 'inactive'];
const secondaryTextArray = ['inactive', 'active'];

class Hero extends Component {

  constructor() {
    super();
    this.state = { textIdx: 0 };
  }

  UNSAFE_componentWillMount() {
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      this.setState({ textIdx: currentIdx + 1 });
    }, 5000);
  }

  UNSAFE_componentWillUnmount() {
    clearInterval(this.timeout);
  }
  
  render() {
    let swappedText = textArray[this.state.textIdx % textArray.length];
    let secondarySwappedText = secondaryTextArray[this.state.textIdx % secondaryTextArray.length];
    let isFirefox = typeof InstallTrigger !== 'undefined';
    
    return (
      <HeroWrapper full={isFirefox === true ? true : false}>
        <H1 className={swappedText}>
          <AnimatedText copy="Welcome" role="heading" />
        </H1>
        <H1 className={secondarySwappedText} as="h2">
          <AnimatedText copy="Välkommen" role="secondary heading" />
        </H1>
      </HeroWrapper>
    );
  }
};

export default Hero;

const HeroWrapper = styled.div`
  border-bottom: 1px solid ${theme.colors.green};
  min-height: calc(100vh - 110px);
  overflow: hidden;
  padding: 20px;
  position: relative;
  width: 100%;

  ${media.tablet`
    min-height: 100vh;
    padding: 0 40px 50px;
  `}

  ${props => props.full && css`
    min-height: 100vh;
  `}

  h1 {
    animation-delay: .6s;
    bottom: 20px;
    left: 20px;
    opacity: 0;
    position: absolute;
    transition: all .6s ease-in-out;

    ${media.tablet`
      bottom: 50px;
      left: 40px;
    `}

    span {
      bottom: -1em;
      opacity: 0;
      position: relative;
      transition: opacity .4s ease-in-out;
    }

    &.active {
      opacity: 1;

      span {
        animation: move-text 4s forwards;
      }
    }
  }

  h2 {
    animation-delay: .6s;
    bottom: 20px;
    left: 20px;
    opacity: 0;
    position: absolute;
    transition: all .6s ease-in-out;

    ${media.tablet`
      bottom: 50px;
      left: 40px;
    `}

    span {
      bottom: -1em;
      opacity: 0;
      position: relative;
      transition: opacity .4s ease-in-out;
    }

    &.active {
      opacity: 1;

      span {
        animation: move-text 4s forwards;
      }
    }
  }
`;