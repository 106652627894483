import React, { Component } from 'react'

class AnimatedText extends Component {
  render(){
    return(
      <div aria-label={this.props.copy} role={this.props.role}>
        {this.props.copy.split("").map(function(char, index){
          let style = {"animationDelay": (0.5 + index / 10) + "s"}
          return <span
            aria-hidden="true"
            key={index}
            style={style}>
            {char}
          </span>;
        })}
      </div>
    );
  }
}

export default AnimatedText;